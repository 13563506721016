<template>
  <div class="toolBar">
    <div class="rightBottomPanel">
      <div class="item" v-for="(item,index) in items" :key="index" @click="handleClick(index)">
        <img class="icon" :src="item.icon" />
        <div class="iconDot" v-if="newQuoteTag"></div>
        <div class="label">{{item.label}}</div>
      </div>
    </div>
    <ai ref="ai"></ai>
  </div>
</template>
<script>
import ai from '@/components/toolBar/ai';
import io from "@/components/socket.io";
import aEntityAPI from "@/api/aEntityAPI";
import { EventBus } from '@/plugins/http/eventBus.js';
export default {
  components: {
    ai
  },
  data() {
    return {
      items: [
        {
          ref: "ai",
          icon: require("@/assets/chatgpt.png"),
          label: "数字调度员",
        }
      ],
      newQuoteTag:false
    };
  },
  mounted() {

    this.initSocket();

    this.existNewQuote();

    let that = this;
    EventBus.$on('openTdoInfoToAiDialog', data=>{
      console.log("openTdoInfoToAiDialog*------------------: ", data);
      that.openTdoInfoToAiDialog(data.tdoId);
    });
  },
  beforeDestroy() {
    // 组件销毁前，移除事件监听
    EventBus.$off('openTdoInfoToAiDialog');
  },
  methods: {
    openTdoInfoToAiDialog(tdoId) {
      this.$refs.ai.open(tdoId);
    },

    closeBarAiDialog(flag) {
      this.$refs.ai.closeBar(flag);
    },

    handleClick(index) {
      this.newQuoteTag = false;
      this.$refs[this.items[index].ref].open()
    },
    existNewQuote(){
      aEntityAPI.request('ShuntingService','existNewQuote',{}).then((res) => {
        if (res.data.success) {
          if (res.data.data > 0) {
            this.newQuoteTag = true;
          }
          
        }
      });
    },

    //socket ---start-----
    initSocket() {
      let that = this;

      let opts = {
        transports: ["websocket"],
      };

      let wsUrl = "";
      if (process.env.VUE_APP_MODE === "PRO") {
        wsUrl = window.location.protocol + "//" + window.location.hostname + ":3001";
      } else if (process.env.VUE_APP_MODE === "TEST") {
        wsUrl =  window.location.protocol + "//" + window.location.hostname + ":3004";
      } else {
        //开发环境
        // wsUrl = window.location.protocol + "//" + window.location.hostname + ":3003";
        // wsUrl = "http://192.168.20.14:3000"
        wsUrl = window.location.protocol + "//" + window.location.hostname + ":3003";
      }
      that.aiWebSocket = io.connect(wsUrl, opts);

      that.aiWebSocket.on("webPushNoCid", function (obj) {
        obj = JSON.parse(obj);
        if (obj.content.newLiaisonRecord) {
          that.$refs.ai.flushDispatcherLiaison(obj.content.tdoId,"newLiaisonRecord");
        }else if (obj.content.newQuoteRecode) {
          that.$refs.ai.flushDispatcherLiaison(obj.content.tdoId,"newQuoteRecode");
          EventBus.$emit('flushTdoQuote',{"tdoNo":obj.content.tdoNo});
        }
      });
      that.aiWebSocket.on("webPush", function (obj) {
        console.log("aiWebSocket.io.push : webPush:", obj);
        // obj = JSON.parse(obj);
        if (obj.newQuote) {
          that.newQuoteTag = true;
          that.$refs.ai.flushData();
        }else if (obj.newTdo) {
          that.$refs.ai.flushData();
        }else if (obj.dispatcherOrders != null && obj.dispatcherOrders ==true){
          console.log("aiWebSocket.io 开启")
        }else if (obj.dispatcherOrders != null && obj.dispatcherOrders ==false){
          console.log("aiWebSocket.closeBar 关闭")
          // that.$refs.ai.closeBar(false);
        }else if (obj.newDriverCallRecord) {
          that.$refs.ai.flushData();
          EventBus.$emit('flushTdoQuote',{"tdoNo":obj.content.tdoNo});
        }

      });
    },
  },
};
</script>
<style lang="less" scoped>
.toolBar .rightBottomPanel {
  position: fixed;
  bottom: 50px;
  right: 0;
  width: 80px;
  background-color: #eff8fc;
  z-index: 9998;
}

.toolBar .rightBottomPanel .item {
  text-align: center;
  height: 50px;
  padding: 5px 0;
  cursor: pointer;
}

.toolBar .rightBottomPanel .item:hover {
  background-color: #deebfd;
}

.toolBar .rightBottomPanel .item .icon {
  width: 28px;
  height: 28px;
}

.toolBar .rightBottomPanel .item .label {
  color: #747d88;
  font-size: 11px;
  font-weight: 600;
}

.iconDot{
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 22px
}
</style>